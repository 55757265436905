import React from 'react';

import { HelpArticle, SubTitle } from 'components/help/helpArticle';
import Code from 'components/help/codeSnippet';
import Link from 'components/ui/link';

import logo from 'img/help/integrations/crisp-logo.png';

const Content = () => {
  return (
    <div>
      <SubTitle>What is Crisp?</SubTitle>
      <p>
        <strong>
          Crisp is a live chat web application with advanced analytics capabilities, help desk and
          multi-channel communication features
        </strong>
        . You can use it to chat with your users, monitor their actions, and enrich their profilies
        with additional data (like LiveSession session replay links). Companies use Crisp to provide
        their customers with support, sell via chat and communicate with people across different
        channels (live chat, e-mail, Facebook and SMS).
      </p>
      <SubTitle className="mt-5">How does this integration work?</SubTitle>
      <p>
        This integration lets you add links to session replays from LiveSession to your Crisp
        account.{' '}
        <strong>
          Thanks to this solution, you can open recordings of a specific user directly from Crisp
          contact profile
        </strong>
        .
      </p>
      <p>
        Every time a new session will start, our code will send an event to Crisp with a link to
        session replay.
      </p>
      <SubTitle className="mt-5">How can I install this integration?</SubTitle>
      <span>To install integration, add the code below before your </span>{' '}
      <Code inline>{'</body>'}</Code> <span> closing tag:</span>
      <Code margin="1rem 0" copy>
        {`<script>
if($crisp && __ls){
__ls("getSessionURL", function(url, isNew){
    if(isNew){
      $crisp.push(["set", "session:data", [[["LiveSession", url]]]]);
    }
})
}
</script>`}
      </Code>
      <div className="alert alert-primary mb-5 mt-5" role="alert">
        To make this integration works you need to have Crisp and LiveSession installed on your
        website. If you haven&apos;t installed our tracking code yet check{' '}
        <Link href="/help/how-to-install-livesession-script/">this article</Link>.
      </div>
      <span>To send LiveSession session replay link as event add code below before</span>{' '}
      <Code inline>{'</body>'}</Code>
      <span> closing tag:</span>
      <Code margin="1rem 0" copy>
        {`<script>
if($crisp && __ls){
__ls("getSessionURL", function(url, isNew){
    if(isNew){
      $crisp.push(["set", "session:event", [[["LiveSession", url, "blue"]]]])
    }
})
}
</script>`}
      </Code>
    </div>
  );
};

const related = [];

export const frontmatter = {
  metaTitle: 'LiveSession and Crisp integration',
  metaDescription: 'Send LiveSession recordings to your Crisp account',
  canonical: '/help/crisp-integration/',
  logoStyle: { width: 130 },
  logo,
};

const Wrapped = HelpArticle(Content);
export default () => (
  <Wrapped
    related={related}
    section="Integrations"
    title={frontmatter.metaTitle}
    {...frontmatter}
  />
);
